import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { media } from '../../utils/MediaResponsive';
import sunShineBgi from '../../images/sun-shine-bgi.png';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100px;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  background: #fff url(${sunShineBgi}) right -187px center no-repeat,
    url(${sunShineBgi}) left -227px top 150px no-repeat;
  background-size: contain, contain;

  @media screen and (max-width: 425px) {
    min-height: unset;
    padding: 3em 0 8em;
  }
`;

const CenterHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CourseName = styled.span`
  margin-top: auto;
  font-size: 0.75em;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 2.3px;
  line-height: 4;
  color: #d22222;
  text-transform: uppercase;
`;

const PageHeadline = styled.span`
  font-size: 48px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #2c3841;
  padding: 0 20px;
  text-align: center;
  text-transform: capitalize;
  ${media.phone`
    font-size: 32px;
   `}
`;

const HeroPanel = ({ courseName, pageName }) => {
  return (
    <Wrapper>
      <CenterHeadingWrapper>
        <CourseName>{courseName}</CourseName>
        <PageHeadline>{pageName}</PageHeadline>
      </CenterHeadingWrapper>
    </Wrapper>
  );
};

HeroPanel.propTypes = {
  courseName: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired
};

export default HeroPanel;
