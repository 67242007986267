import Img from 'gatsby-image';
import styled from 'styled-components';
import Icon from '../Icon';

export const StyleImage = styled(Img)``;
export const StyledIcon = styled(Icon)``;
export const Container = styled.div`
  background-color: ${p => p.theme.white};
  ${StyledIcon} {
    color: #607187;
  }
`;
