/* eslint-disable */
import 'swiper/swiper.min.css';

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Swiper from 'react-id-swiper';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';

import { DesktopAndUp, TabletAndDown } from '../../../components/Breakpoint';
import Container from '../../../components/Container';
import HeroPanel from '../../../components/HeroPanel';
import { MainLayout } from '../../../components/Layout';
import {
  StyleImage,
  StyledIcon
} from '../../../components/Layout/HelperComponents';
import SEO from '../../../components/SEO';
import withI18next from '../../../components/withI18next';
import { media } from '../../../utils/MediaResponsive';

const SectionHeadline = styled.div`
  font-size: 1.95rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2c3841;
  margin: 30px 0;

  ${props =>
    props.center &&
    css`
      margin: 0 auto;
      text-align: center;
    `}

  ${media.desktop`
    padding: 0 20px;
    text-align: center;
  `}
  ${media.tablet`
    margin-top: 50px;
    padding: 0 10px;
  `}
  ${media.phone`text-align:center;margin-top: 36px;`}
`;

const Ecosystem = ({ data, pageContext }) => {
  const { title, keywords, description } = data.gcms.seos[0];
  const { t } = useTranslation(['ielts-ecosystem', 'ielts']);

  return (
    <MainLayout headerFixed={false} hideSideCTA>
      <SEO
        title={title}
        keywords={keywords}
        description={description}
        meta={pageContext.meta}
      />
      <Container>
        <HeroPanel
          courseName={t('ielts:courseName')}
          pageName={t('ielts-ecosystem:title')}
        />
      </Container>
      <Container bgc="#f9f9f9">
        <DolOnlineTestBanner data={data} />
      </Container>
      <Container>
        <DolVocabularyBanner data={data} />
      </Container>
      <Container bgc="#f9f9f9">
        <EssayCorrectionBanner data={data} />
      </Container>
      <Container>
        <PronunciationChecker data={data} />
      </Container>
      <Container bgc="#f9f9f9">
        <LearningManagementPlatformBanner data={data} />
        <BannerContainer>
          <SeparatorLine />
        </BannerContainer>
      </Container>
    </MainLayout>
  );
};
Ecosystem.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      seos: PropTypes.any
    })
  }),
  pageContext: PropTypes.shape({
    meta: PropTypes.any
  })
};

// #region LearningManagementPlatformBanner
const LearningManagementPlatformBanner = ({ data }) => {
  const { t } = useTranslation('ielts-ecosystem');

  return (
    <LearningManagementPlatformBannerWrapper>
      <AppImageDemoContainer>
        <SectionHeadline>{t('app5')}</SectionHeadline>
        <Images>
          <FemaleUserImage fluid={data.FemaleUserImage.childImageSharp.fluid} />
          <MLPAppDemoImage fluid={data.MLPAppDemoImage.childImageSharp.fluid} />
          <MaleUserImage fluid={data.MaleUserImage.childImageSharp.fluid} />
        </Images>
        <DownloadButtons
          data={data}
          urlAppStore="https://apps.apple.com/us/app/dol-learning-management/id1458702339"
          urlPlayStore="https://play.google.com/store/apps/details?id=com.dolonline.lms&hl=en"
        />
      </AppImageDemoContainer>
      <BannerInfo>
        <SectionHeadline>{t('app5')}</SectionHeadline>
        <GridRow>
          <GridItem>
            <StyledIcon type="GlyphsMaterial" />
            <FeatureBrief>{t('app5description1name')}</FeatureBrief>
            <FeatureDescription>
              {t('app5description1content')}
            </FeatureDescription>
          </GridItem>
          <GridItem>
            <StyledIcon type="GlyphsWorldClass" />
            <FeatureBrief>{t('app5description2name')}</FeatureBrief>
            <FeatureDescription>
              {t('app5description2content')}
            </FeatureDescription>
          </GridItem>
        </GridRow>
        <GridRow>
          <GridItem>
            <StyledIcon type="GlyphsTeacher" />
            <FeatureBrief>{t('app5description3name')}</FeatureBrief>
            <FeatureDescription>
              {t('app5description3content')}
            </FeatureDescription>
          </GridItem>
          <GridItem>
            <StyledIcon type="GlyphsPerformance" />
            <FeatureBrief>{t('app5description4name')}</FeatureBrief>
            <FeatureDescription>
              {t('app5description4content')}
            </FeatureDescription>
          </GridItem>
        </GridRow>
      </BannerInfo>
    </LearningManagementPlatformBannerWrapper>
  );
};

LearningManagementPlatformBanner.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired
};

const FeatureBrief = styled.p`
  font-size: 16px;
  font-weight: ${p => (p.light ? 300 : 600)};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2c3841;
  margin-bottom: 20px;
`;
const FeatureDescription = styled.p`
  font-size: 1rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #5d6a74;
  text-align: left;

  @media screen and (max-width: 1024px) {
    text-align: center;
    margin: 0 auto;
  }
`;

const BannerContainer = styled.div`
  padding: 3em 150px 0;

  @media screen and (max-width: 1024px) {
    padding: 0 5%;
  }
`;

const LearningManagementPlatformBannerWrapper = styled(BannerContainer)`
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: flex-start;
  ${media.phone`padding-bottom: 40px;`}
`;

const AppImageDemoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  text-align: right;
  flex-direction: column;
  flex-grow: 1;

  ${media.giant`
    padding-bottom: 200px;
  `}
  ${media.desktop`
    padding-bottom: 100px;
  `}

  ${media.tablet`
    padding-bottom: 0;
  `}
  ${media.bigPhone`
    padding-bottom: 0;
  `}
  ${SectionHeadline} {
    font-size: 24px;
    line-height: 30px;
    display: none;
    ${media.phone`
      display:block;
      padding: 0 40px;
      text-align:center;
  `}
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.phone`width:100%;`}
`;

const FemaleUserImage = styled(StyleImage)`
  margin-right: 10px;
  top: 300px;
  width: 150px;
  height: 150px;
  ${media.phone`width: 73px; height:73px;top: 150px;margin-left: -10px;`}
`;
const MaleUserImage = styled(StyleImage)`
  margin-left: -10px;
  top: 90px;
  width: 150px;
  height: 150px;
  ${media.phone`width: 73px;height:73px;top: 0px;margin-left: 0px;`}
`;
const MLPAppDemoImage = styled(Img)`
  width: 184px;
  margin-top: 160px;
  ${media.giant`margin-top: 150px;`}
  ${media.tablet`margin-top: 100px;`}
  ${media.phone`width:125px; margin-top:20px;`};
`;

const BannerInfo = styled.div`
  flex-basis: 50%;
  margin-top: 70px;
  flex-grow: 1;

  ${StyledIcon} {
    font-size: 30px;
  }
  ${media.tablet`
    margin-top:0px;
  `}
  ${media.phone`
    margin-top:30px;
  `}
  ${FeatureDescription} {
    ${media.phone`
      display:none;
  `}
  }

  ${SectionHeadline} {
    ${media.phone`display: none;`}
  }
`;

const PronunContentWrapper = styled.div`
  text-align: ${p => p.textAlign || 'left'};
  margin-bottom: 3em;

  @media screen and (max-width: 425px) {
    padding: 0 1em;
  }
`;

const PronunciationCheckerWrapper = styled(BannerContainer)`
  padding: 3em 0;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  ${SectionHeadline} {
    padding-left: 10%;
  }

  @media screen and (max-width: 500px) {
    flex-flow: column nowrap;
    align-items: center;

    ${SectionHeadline} {
      font-size: 2em;
    }

    ${PronunContentWrapper}:nth-child (2) {
      margin-top: 1em;
    }
  }
`;

const PronunContentContainer = styled.div`
  max-width: 450px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  margin: 0 5em;

  @media screen and (max-width: 500px) {
    max-width: unset;
    margin: 0;

    * {
      text-align: center;
    }
  }
`;

const PronunImgMobile = styled(Img)`
  display: none !important;

  @media screen and (max-width: 425px) {
    display: block;
    margin-bottom: 2em;
  }
`;

const PronunContentOrder = styled.p`
  font-weight: bold;
  font-size: 2.5em;
  color: ${p => p.theme.brand};

  @media screen and (max-width: 425px) {
    font-size: 2em;
  }
`;

const PronunContentTitle = styled.p`
  font-size: 1.125em;
  font-weight: 700;
  color: #2c3841;
  margin: 1em 0;
`;

const PronunContentText = styled.p`
  font-weight: 300;
  font-size: 1.25em;
  line-height: 2;
  color: black;

  @media screen and (max-width: 425px) {
    font-size: 1em;
  }
`;

const PronunImage = styled(Img)`
  & + & {
    margin-left: 3em;
  }

  @media screen and (max-width: 1024px) {
    & + & {
      margin-left: 0;
      margin-top: 1.5em;
    }
  }

  @media screen and (max-width: 768px) {
    width: 250px !important;
    height: calc(250px * 2.176) !important;
  }
`;

const PronunImageContainer = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-flow: column nowrap;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`;
// #endregion

const PronunContent = ({ order, title, text, textAlign, ...rest }) => {
  return (
    <PronunContentWrapper textAlign={textAlign} {...rest}>
      <PronunContentOrder>{order}</PronunContentOrder>
      <PronunContentTitle>{title}</PronunContentTitle>
      <PronunContentText>{text}</PronunContentText>
    </PronunContentWrapper>
  );
};

PronunContent.propTypes = {
  order: PropTypes.any,
  text: PropTypes.any,
  textAlign: PropTypes.any,
  title: PropTypes.any
};

const PronunciationChecker = ({ data }) => {
  const { t } = useTranslation('ielts-ecosystem');

  return (
    <PronunciationCheckerWrapper>
      <SectionHeadline>{t('app4')}</SectionHeadline>
      <PronunShowcase>
        <PronunImageContainer>
          <PronunImage fixed={data.pronun1.childImageSharp.fixed} />
          <PronunImage fixed={data.pronun2.childImageSharp.fixed} />
        </PronunImageContainer>
        <PronunContentContainer>
          <PronunContent
            textAlign="left"
            order={t('app4description1Number')}
            title={t('app4description1name')}
            text={t('app4description1content')}
          />
          <PronunImgMobile fixed={data.pronun1mobile.childImageSharp.fixed} />

          <PronunContent
            textAlign="left"
            order={t('app4description2Number')}
            title={t('app4description2name')}
            text={t('app4description2content')}
          />
          <PronunImgMobile fixed={data.pronun2mobile.childImageSharp.fixed} />
        </PronunContentContainer>
      </PronunShowcase>
    </PronunciationCheckerWrapper>
  );
};

const PronunShowcase = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 2em;
`;

PronunciationChecker.propTypes = {
  data: PropTypes.shape({
    pronun1: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any
      })
    }),
    pronun1mobile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any
      })
    }),
    pronun2: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any
      })
    }),
    pronun2mobile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any
      })
    })
  })
};

function getShortContent(t) {
  return t && t.length > 90 ? `${t.substring(0, 90).trim()}...` : t;
}

// #region DolOnlineTestBanner
const DolOnlineTestBanner = ({ data }) => {
  const { t } = useTranslation('ielts-ecosystem');

  return (
    <DolOnlineTestBannerWrapper>
      <SectionHeadline>{t('app1')}</SectionHeadline>
      <DolOnlineTestBannerContent>
        <DOTStep1>
          <DOTStepContent
            stepNumber={t('app1description1Number')}
            headline={t('app1description1name')}
            textContent={t('app1description1content')}
            fixedImage={data.SkillListSnapshotImage.childImageSharp.fixed}
          />
        </DOTStep1>
        <DOTStep2>
          <DOTStepContent
            stepNumber={t('app1description2Number')}
            headline={t('app1description2name')}
            textContent={t('app1description2content')}
            sortTextContent={getShortContent(t('app1description2content'))}
            fixedImage={data.ListeningTestSnapshotImage.childImageSharp.fixed}
          />
        </DOTStep2>
        <DOTStep3>
          <DOTStepContent
            stepNumber={t('app1description3Number')}
            headline={t('app1description3name')}
            textContent={t('app1description3content')}
            sortTextContent={getShortContent(t('app1description3content'))}
            fluidImage={data.GmatTestSnapshotImage.childImageSharp.fluid}
          />
        </DOTStep3>
      </DolOnlineTestBannerContent>
    </DolOnlineTestBannerWrapper>
  );
};

DolOnlineTestBanner.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired
};

const DolOnlineTestBannerWrapper = styled(BannerContainer)``;
const DolOnlineTestBannerContent = styled.div`
  flex-direction: column;
  height: 670px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${media.tablet`height:630px;`}
  ${media.phone`flex-wrap: nowrap;height: auto;`}
`;

// #region DOTStep
const DOTStepContent = ({
  stepNumber,
  headline,
  textContent,
  sortTextContent = textContent,
  fixedImage,
  fluidImage
}) => (
  <>
    <DOTStepNumber>{stepNumber}</DOTStepNumber>
    <DOTStepTextNImage>
      <DOTStepNumberOnTablet>{stepNumber}</DOTStepNumberOnTablet>
      <DOTStepTextNImageHeadline>{headline}</DOTStepTextNImageHeadline>
      <DOTStepTextNImageTextContent className="--long">
        {textContent}{' '}
      </DOTStepTextNImageTextContent>
      <DOTStepTextNImageTextContent className="--sort">
        {sortTextContent}{' '}
      </DOTStepTextNImageTextContent>
      {fixedImage && <DOTBannerImage fixed={fixedImage} />}
      {fluidImage && <DOTBannerImage fluid={fluidImage} />}
    </DOTStepTextNImage>
  </>
);

DOTStepContent.propTypes = {
  stepNumber: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  textContent: PropTypes.string.isRequired,
  sortTextContent: PropTypes.string.isRequired,
  fixedImage: PropTypes.instanceOf(Object),
  fluidImage: PropTypes.instanceOf(Object)
};

DOTStepContent.defaultProps = {
  fixedImage: null,
  fluidImage: null
};

const DOTBannerImage = styled(Img)`
  margin-top: auto;

  @media screen and (max-width: 425px) {
    margin: 0;
  }
`;

const DOTStepStyled = styled.div`
  padding-top: 10px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    padding-left: 10px;
    width: 50%;
  }

  ${media.phone`width:100%;`}
`;

const DOTStep1 = styled(DOTStepStyled)`
  width: 40%;
  background-color: #d9dde2;
  color: #2c3841;
  height: 336px;
  ${media.phone`min-height: 284px;height: 284px;
    ${DOTBannerImage}{
      margin-left:50px;
    }
  `}
`;
const DOTStep2 = styled(DOTStepStyled)`
  width: 40%;
  background-color: #f4f4f4;
  color: #2c3841;
  height: 269px;
  ${media.phone`min-height: 217px;height: 217px;`}
`;
const DOTStep3 = styled(DOTStepStyled)`
  width: 60%;
  background-color: #2d3942;
  color: #fff;
  height: 607px;

  ${media.phone`
    height: 341px;

    background-color:#607187;
  `}
`;
const DOTStepNumber = styled.span`
  font-size: 60px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.48;
  letter-spacing: normal;
  margin-right: 0;
  ${media.desktop`margin-right: 10px;`}
  ${media.giant`margin-right: 20px;`}
  text-align: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const DOTStepNumberOnTablet = styled(DOTStepNumber)`
  display: none;

  @media screen and (max-width: 1024px) {
    display: inline;
  }

  @media screen and (max-width: 425px) {
    display: none;
  }
`;

const DOTStepTextNImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;
  padding-left: 12px;

  @media screen and (max-width: 1024px) {
    padding-top: 0;
  }

  ${media.phone`
    width:100%;
    padding:0;
  `}
`;

const DOTStepTextNImageHeadline = styled.span`
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  font-size: 24px;

  @media screen and (max-width: 1024px) {
    line-height: 1.4;
  }

  ${media.phone`
    font-weight: 600;
    line-height: 46px;
    font-size: 16px;
  `}
`;

const DOTStepTextNImageTextContent = styled.span`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 60px;

  @media screen and (max-width: 1024px) {
    padding-right: 10px;
  }

  &.--sort {
    display: none;
  }
  ${media.phone`
    font-weight: normal;
    line-height: 23px;
    font-size: 14px;
    opacity: 0.7;
    width:100%;
    padding-right: 10px;

    &.--sort {display: block;}
    &.--long {display: none;}

  `}
`;
// #endregion DOTStep
// #endregion DolOnlineTestBanner

// #region DolVocabularyBanner

const params = {
  slidesPerView: 1,
  spaceBetween: 30,
  pagination: {
    el: '.dol-vocab-ecosystem',
    clickable: true
  }
};

const Bullets = styled.div.attrs({ className: 'dol-vocab-ecosystem' })``;

const DolVocabularyBanner = ({ data }) => {
  const { t } = useTranslation('ielts-ecosystem');
  const dolVocabularyImages = staticImage => [
    <DolVocabularyDemoImage
      key="VocabularyMadeSimple"
      iconType="VocabularyMadeSimple"
      headline={t('app2description1name')}
      description={t('app2description1content')}
      fixedImage={staticImage.DemoDolVocab2Image.childImageSharp.fixed}
    />,
    <DolVocabularyDemoImage
      key="LearningFasterWithQuizAndGame"
      iconType="LearningFasterWithQuizAndGame"
      headline={t('app2description2name')}
      description={t('app2description2content')}
      fixedImage={staticImage.DemoDolVocab1Image.childImageSharp.fixed}
    />,

    <DolVocabularyDemoImage
      key="AidingInClassLearningIcon"
      iconType="AidingInClassLearningIcon"
      headline={t('app2description3name')}
      description={t('app2description3content')}
      fixedImage={staticImage.DemoDolVocab3Image.childImageSharp.fixed}
    />
  ];
  return (
    <DolVocabularyBannerWrapper>
      <StyleImage
        className="__dolVocabularyBannerBg1"
        fixed={data.VocabBgPen.childImageSharp.fixed}
      />
      <StyleImage
        className="__dolVocabularyBannerBg2"
        fixed={data.VocabBgCoffee.childImageSharp.fixed}
      />
      <SectionHeadline center>{t('app2')}</SectionHeadline>
      <TabletAndDown>
        <DolVocabularyImages>
          <Swiper {...params}>
            {dolVocabularyImages(data).map((dolVocabularyDemoImage, idx) => (
              <div key={`img-${idx}`}>{dolVocabularyDemoImage}</div>
            ))}
          </Swiper>
          <Bullets />
        </DolVocabularyImages>
      </TabletAndDown>
      <DesktopAndUp>
        <DolVocabularyImages>{dolVocabularyImages(data)}</DolVocabularyImages>
      </DesktopAndUp>
      <SeparatorLine />
      <DownloadButtonsWrapper>
        <DownloadButtons
          data={data}
          urlAppStore="https://apps.apple.com/vn/app/dol-vocabulary-builder/id1458861608"
          urlPlayStore="https://play.google.com/store/apps/details?id=com.dolonline.vocab"
        />
      </DownloadButtonsWrapper>
    </DolVocabularyBannerWrapper>
  );
};
DolVocabularyBanner.propTypes = {
  data: PropTypes.shape({
    VocabBgCoffee: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any
      })
    }),
    VocabBgPen: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any
      })
    })
  })
};

const DownloadButtonsWrapper = styled.div`
  margin: 0 auto;
  margin-top: -51px;
  background-color: #fbfbfb;
  max-width: 450px;
`;
const DolVocabularyImages = styled.div`
  width: 100%;
  margin-top: 42px;
  margin-bottom: 78px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    display: block;
    .swiper-pagination{
      bottom: 150px !important;
      ${media.phone`
      bottom: 300px !important;
      `}
      .swiper-pagination-bullet-active {
        background-color: #6f6f6f;
      }
    }

  `}
`;

const DolVocabularyDemoImage = ({
  iconType,
  headline,
  fixedImage,
  description
}) => (
  <DolVocabularyDemoImageWrapper>
    <StyleImage fixed={fixedImage} />
    <DolVocabIconAndText>
      <StyledIcon type={iconType} />
      <FeatureBrief light>{headline}</FeatureBrief>
      <FeatureDescription>{description}</FeatureDescription>
    </DolVocabIconAndText>
  </DolVocabularyDemoImageWrapper>
);

DolVocabularyDemoImage.propTypes = {
  iconType: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  fixedImage: PropTypes.instanceOf(Object).isRequired,
  description: PropTypes.string.isRequired
};

const DolVocabularyDemoImageWrapper = styled.div`
  flex-basis: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StyleImage} {
    margin-bottom: 42px;
  }

  ${StyledIcon} {
    font-size: 1.875rem;
    margin-bottom: 24px;
  }

  ${FeatureBrief} {
    margin-bottom: 16px;
  }

  ${media.phone`
    ${FeatureDescription}{
      width: 90%;
      height:150px;
    }
  `}
`;

const DolVocabIconAndText = styled.div`
  ${media.phone`
    padding: 10px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 3px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 0 20px;
    margin-top:40px;
  `}
`;

const DolVocabularyBannerWrapper = styled(BannerContainer)`
  position: relative;

  @media screen and (max-width: 1024px) {
    padding-top: 14px;
  }

  .__dolVocabularyBannerBg1 {
    position: absolute !important;
    top: 26%;
    left: 40%;
    transform: translateX(-40%);
    ${media.tablet`
      top: 24%;
      transform: translateY(-24%);
      left: 36%;
      transform: translateX(-36%);
    `};
    ${media.bigPhone`
      top: 24%;
      transform: translateY(-24%);
      left: 5%;
      transform: translateX(-5%);
    `};
  }
  .__dolVocabularyBannerBg2 {
    position: absolute !important;
    top: 20%;
    right: 38%;
    transform: translateX(-38%);
    ${media.giant`
      right: 30%;
      transform: translateX(-30%);
    `}
    ${media.tablet`
      right: 26%;
      transform: translateX(-26%);
    `};
    ${media.phone`
      top:14%;
      transform: translateY(-14%);
      right:6%;
      transform: translateX(-6%);
    `}
  }
`;
// #endregion

// #region EssayCorrectionBanner
const EssayCorrectionBanner = ({ data }) => {
  const { t } = useTranslation('ielts-ecosystem');

  return (
    <EssayCorrectionBannerWrapper>
      <SectionHeadline>{t('app3')}</SectionHeadline>
      <EssayCorrectionFeatureLeftSide>
        <EssayCorrectionFeatureContent
          numberHeadline={t('app3description1Number')}
          textHeadline={t('app3description1name')}
          textDescription={t('app3description1content')}
        />
        <Img
          className="__demoImage1"
          fixed={data.EssayAppDemoImage1.childImageSharp.fixed}
          alt="childImageSharp"
        />
        <Img
          className="__demoImage2"
          fixed={data.EssayAppDemoImage2.childImageSharp.fixed}
          alt="childImageSharp"
        />
      </EssayCorrectionFeatureLeftSide>
      <EssayCorrectionFeatureRightSide>
        <EssayCorrectionFeatureContent
          numberHeadline={t('app3description2Number')}
          textHeadline={t('app3description2name')}
          textDescription={t('app3description2content')}
        />
        <Img
          className="__demoImage1"
          fixed={data.EssayAppDemoImage3.childImageSharp.fixed}
          alt="childImageSharp"
        />
      </EssayCorrectionFeatureRightSide>
    </EssayCorrectionBannerWrapper>
  );
};
EssayCorrectionBanner.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired
};

const EssayCorrectionFeatureContent = ({
  numberHeadline,
  textHeadline,
  textDescription
}) => (
  <>
    <ECFNumberHeadline>{numberHeadline}</ECFNumberHeadline>
    <ECFTextHeadline>{textHeadline}</ECFTextHeadline>
    <ECFTextDescription>{textDescription}</ECFTextDescription>
  </>
);

EssayCorrectionFeatureContent.propTypes = {
  numberHeadline: PropTypes.string.isRequired,
  textHeadline: PropTypes.string.isRequired,
  textDescription: PropTypes.string.isRequired
};

const ECFNumberHeadline = styled.p`
  line-height: 89px;
  font-size: 60px;
  ${media.phone`display:none;`}
`;

const ECFTextHeadline = styled.p`
  font-weight: 300;
  line-height: 46px;
  font-size: 24px;
  ${media.phone`
    font-weight: 600;
    line-height: 46px;
    font-size: 16px;
  `}
`;
const ECFTextDescription = styled.p`
  opacity: 0.7;
  width: 60%;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;

  @media screen and (max-width: 768px) {
    width: auto;
    margin-bottom: 1em;
  }
`;
const EssayCorrectionFeatureWrapper = styled.div`
  display: inline-block;
  padding-top: 29px;
  padding-left: 40px;
  height: 600px;
  position: relative;
  overflow: hidden;
  flex-basis: 50%;
  ${media.phone`
    flex-basis:100%;
    height: 530px;
  `}
`;

const EssayCorrectionFeatureLeftSide = styled(EssayCorrectionFeatureWrapper)`
  color: #fff;
  background-color: #2d3942;

  .__demoImage1 {
    position: absolute !important;
    right: 275px;
    bottom: -15px;

    @media screen and (max-width: 425px) {
      display: none;
    }
  }
  .__demoImage2 {
    position: absolute !important;
    right: 15px;
    bottom: 0;
    box-shadow: 5px 1px 20px 10px #00000017;
    ${media.phone`left: 20px;`}
  }
`;

const EssayCorrectionFeatureRightSide = styled(EssayCorrectionFeatureWrapper)`
  background-color: #f4f4f4;
  color: #2c3841;

  .__demoImage1 {
    position: absolute !important;
    left: 10px;
    bottom: 0;
    ${media.phone`
      left:40px;
      bottom: -40px;
      box-shadow: 5px 1px 20px 10px #00000017;
   `}
  }
`;
const EssayCorrectionBannerWrapper = styled(BannerContainer)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 40px;
  ${SectionHeadline} {
    flex-basis: 100%;
  }
`;
// #endregion EssayCorrectionBanner
// #region Share Styled Components

// #region DownloadButtons
const DownloadButtons = ({ data, urlPlayStore, urlAppStore }) => (
  <StyledDownloadButtons>
    <div
      onClick={() => {
        window.open(urlPlayStore, '_blank');
      }}
    >
      <PlaystoreImage fluid={data.PlaystoreImage.childImageSharp.fluid} />
    </div>
    <div
      onClick={() => {
        window.open(urlAppStore, '_blank');
      }}
    >
      <AppstoreImage fluid={data.AppstoreImage.childImageSharp.fluid} />
    </div>
  </StyledDownloadButtons>
);

DownloadButtons.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  urlPlayStore: PropTypes.string.isRequired,
  urlAppStore: PropTypes.string.isRequired
};

const StyledDownloadButtons = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
`;

const AppstoreImage = styled(Img)`
  cursor: pointer;
  margin: auto 10px;
  width: 167px;
  ${media.phone`width: 133px;`}
`;
const PlaystoreImage = styled(Img)`
  cursor: pointer;
  margin: auto 8px;
  width: 167px;
  ${media.phone`width: 133px;`}
`;
// #endregion

const SeparatorLine = styled.hr`
  width: 100%;
  margin: 0 auto;
  ${media.phone`width:80%;`}
`;

const GridRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 4.5rem;
  ${media.phone`margin-top: 0;`}
`;
const GridItem = styled.div`
  width: 290px;
  padding-right: 10px;
  ${media.tablet`padding-right: 20px;`};
  ${media.giant`padding-right: 50px;`};

  box-sizing: border-box;
  flex-basis: 48%;
  ${media.phone`
    flex-basis:100%;
    display: flex;
    padding-right:0;
    margin-top:10px;
    ${FeatureBrief}{
      margin-left:20px;
    }
  `};

  ${media.desktop`
    padding: 0 40px;
  `};

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
`;

export const pageQuery = graphql`
  query {
    gcms {
      seos(where: { path: "ielts/chuoi-ung-dung" }) {
        path
        title
        description
        keywords
      }
    }
    FemaleUserImage: file(
      relativePath: { eq: "ielts/app-ecosystem/girl-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    MLPAppDemoImage: file(
      relativePath: { eq: "ielts/app-ecosystem/iphone-lms.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    MaleUserImage: file(relativePath: { eq: "ielts/app-ecosystem/boy-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    PlaystoreImage: file(relativePath: { eq: "Playstore.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    AppstoreImage: file(relativePath: { eq: "Appstore.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    SkillListSnapshotImage: file(
      relativePath: { eq: "ielts/app-ecosystem/skillListSnapshot.png" }
    ) {
      childImageSharp {
        fixed(width: 210, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    GmatTestSnapshotImage: file(
      relativePath: { eq: "ielts/app-ecosystem/gmatTestSnapshot.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 604) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ListeningTestSnapshotImage: file(
      relativePath: { eq: "ielts/app-ecosystem/listeningTestSnapshot.png" }
    ) {
      childImageSharp {
        fixed(width: 354, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    DemoDolVocab1Image: file(
      relativePath: { eq: "ielts/app-ecosystem/demoDolVocab1.png" }
    ) {
      childImageSharp {
        fixed(width: 166, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    DemoDolVocab2Image: file(
      relativePath: { eq: "ielts/app-ecosystem/demoDolVocab2.png" }
    ) {
      childImageSharp {
        fixed(width: 166, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    DemoDolVocab3Image: file(
      relativePath: { eq: "ielts/app-ecosystem/demoDolVocab3.png" }
    ) {
      childImageSharp {
        fixed(width: 166, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    EssayAppDemoImage1: file(
      relativePath: { eq: "ielts/app-ecosystem/essay-app-demo-1.png" }
    ) {
      childImageSharp {
        fixed(width: 260, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    EssayAppDemoImage2: file(
      relativePath: { eq: "ielts/app-ecosystem/essay-app-demo-2.png" }
    ) {
      childImageSharp {
        fixed(width: 264, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    EssayAppDemoImage3: file(
      relativePath: { eq: "ielts/app-ecosystem/essay-app-demo-3.png" }
    ) {
      childImageSharp {
        fixed(width: 506, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    VocabBgCoffee: file(
      relativePath: { eq: "ielts/app-ecosystem/vocab-bg-coffee.png" }
    ) {
      childImageSharp {
        fixed(width: 106, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    VocabBgPen: file(
      relativePath: { eq: "ielts/app-ecosystem/vocab-bg-pen.png" }
    ) {
      childImageSharp {
        fixed(width: 180, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    pronun1: file(relativePath: { eq: "ielts/app-ecosystem/pronun-1.png" }) {
      childImageSharp {
        fixed(height: 500, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    pronun2: file(relativePath: { eq: "ielts/app-ecosystem/pronun-2.png" }) {
      childImageSharp {
        fixed(height: 500, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }

    pronun1mobile: file(
      relativePath: { eq: "ielts/app-ecosystem/pronun1mobile.png" }
    ) {
      childImageSharp {
        fixed(width: 375, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    pronun2mobile: file(
      relativePath: { eq: "ielts/app-ecosystem/pronun2mobile.png" }
    ) {
      childImageSharp {
        fixed(width: 375, quality: 100) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`;

export default withI18next()(Ecosystem);
