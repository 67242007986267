import React from 'react';
import PropTypes from 'prop-types';

import { hideAt } from '../../utils/makeResponsive';

const Breakpoint = ({ min, max, children }) => {
  const Component = hideAt({ min, max });
  return <Component>{children}</Component>;
};

Breakpoint.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  children: PropTypes.node
};

Breakpoint.defaultProps = {
  min: '',
  max: '',
  children: null
};

export default Breakpoint;
