import styled from 'styled-components';

const buildStyles = rulesets =>
  rulesets.reduce(
    (cssString, { constraint, width, rules }) =>
      `${cssString} @media (${constraint}-width: ${width}) { ${rules} }`,
    ''
  );

const makeResponsive = (rulesets, tagName = 'div') =>
  styled(tagName)`
    width: 100%;
    ${buildStyles(rulesets)}
  `;

export const hideAt = breakpoints => {
  const rulesets = Object.entries(breakpoints).reduce(
    (result, [constraint, width]) => [
      ...result,
      {
        constraint,
        width,
        rules: 'display: none;'
      }
    ],
    []
  );

  return makeResponsive(rulesets);
};

export default makeResponsive;
